// Toggle the visibility of the hamburger menu
function toggle_hamburger_menu() {
  const hamburger = document.getElementById("hamburger_menu-button");
  const menu = document.getElementById("hamburger_menu_items_holder");

  if (hamburger != null && menu != null) {
      hamburger.addEventListener("click", () => {
          menu.classList.toggle("show");
      });
    //   hamburger.addEventListener("touchend", () => {
    //       menu.classList.toggle("show");
    //   });
  }
}

// Load the menu from a partial file
export function loadMenu() {
    fetch("/partials/_menu.html")
        .then(response => response.text())
        .then(data => {
            document.body.insertAdjacentHTML("afterbegin", data);
            toggle_hamburger_menu();
        })
        .catch(error => {
            console.error("Error loading menu: ", error);
        });
}
